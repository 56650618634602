// Library imports
import React from 'react';
import { element } from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const TwoColumnView = ({ columnOneJSX, columnTwoJSX }) => {
    return (
        <Row>
            <Col xs={6} style={{ paddingRight: '3px' }}>
                {columnOneJSX}
            </Col>
            <Col xs={6} style={{ paddingLeft: '3px' }}>
                {columnTwoJSX}
            </Col>
        </Row>
    );
};

TwoColumnView.defaultProps = {
    columnOneJSX: null,
    columnTwoJSX: null,
};

TwoColumnView.propTypes = {
    columnOneJSX: element,
    columnTwoJSX: element,
};

export default TwoColumnView;
