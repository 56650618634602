// Library imports
import React, { useState, useEffect } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
// Component imports
import LineChart from '../../components/graphs/LineChart';
import PieChart from '../../components/graphs/PieChart';
import { LoadingSpinner } from '../../components/LoadingSpinner';
// Util imports
import { capitalize } from '../../utils';

const StatGraphs = () => {
    // Redux
    const { sport, loaders, algoData } = useSelector(state => state.app);
    // Local state
    const [activeGraphs, setActiveGraphs] = useState(
        sport === 'mlb' || sport === 'nhl'
            ? {
                  spread: 'line',
                  powerLine: 'line',
                  moneyline: 'line',
                  dogs: 'line',
              }
            : { spread: 'line', moneyline: 'line', dogs: 'line' },
    );

    useEffect(() => {
        setActiveGraphs(
            sport === 'mlb' || sport === 'nhl'
                ? {
                      spread: 'line',
                      powerLine: 'line',
                      moneyline: 'line',
                      dogs: 'line',
                  }
                : { spread: 'line', moneyline: 'line', dogs: 'line' },
        );
    }, [sport]);

    const getWinnerType = graphType => {
        if (graphType === 'dogs') return 'dogWinner';
        if (graphType === 'moneyline') return 'algoMoneylineCorrect';
        if (graphType === 'spread') return 'algoSpreadCorrect';
        if (graphType === 'powerLine') return 'algoPowerlineWinner';
        return 'algoSpreadCorrect';
    };

    if (loaders.find(loader => loader === 'algo-data')) {
        return (
            <div className="grid-content-wrapper">
                <LoadingSpinner loading={true} />
            </div>
        );
    }

    return (
        <div className="grid-content-wrapper">
            {Object.keys(activeGraphs).map((graphType, i) => {
                return (
                    <Card key={i} className="grid-item-wrapper">
                        <Card.Body>
                            <Card.Title>{capitalize(graphType)}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{`(${algoData[graphType].wins}-${algoData[graphType].losses})`}</Card.Subtitle>
                            <Nav variant="tabs" activeKey={activeGraphs[graphType]}>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={`line`}
                                        onClick={() =>
                                            setActiveGraphs({
                                                ...activeGraphs,
                                                [graphType]: 'line',
                                            })
                                        }
                                    >
                                        Line
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={`pie`}
                                        onClick={() =>
                                            setActiveGraphs({
                                                ...activeGraphs,
                                                [graphType]: 'pie',
                                            })
                                        }
                                    >
                                        Pie
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Card.Text className="text-center mt-4" style={{ marginBottom: '2rem' }}>
                                {graphType === 'spread'
                                    ? "Line Prophet's predicted score gives an alternate spread. If the alternate spread is more accurate than the one Vegas uses that is considered a win for the system."
                                    : graphType === 'dogs'
                                    ? "If the Line Prophet predicted score shows what Vegas considers a 'dog' winning outright, and then it does, that is considered a win for the system."
                                    : "The win/loss percentage of the Line Prophet generated moneyline.  If our 'powerline' shows a team winning and they do it counts as a win on this chart."}
                            </Card.Text>
                            {activeGraphs[graphType] === 'line' ? (
                                <LineChart
                                    xAxisLabel={'Day'}
                                    yAxisLabel={'Win %'}
                                    symbol="%"
                                    fixedAmount={0}
                                    data={[...new Set(algoData.games.map(g => moment(g.dateTime).format('MM/DD/YYYY')))].reverse().map(dateTime => {
                                        const dateTimeGames = algoData.games.filter(g => moment(g.dateTime).isSame(moment(dateTime), 'day'));
                                        const filter = getWinnerType(graphType);
                                        return {
                                            x: dateTime,
                                            y: dateTimeGames.length ? (dateTimeGames.filter(g => g[filter]).length / dateTimeGames.length) * 100 : 0,
                                        };
                                    })}
                                />
                            ) : (
                                <PieChart
                                    data={[
                                        {
                                            x: `Wins: ${Math.trunc(
                                                (algoData[graphType].wins / (algoData[graphType].wins + algoData[graphType].losses)) * 100,
                                            )}%`,
                                            y: Math.trunc((algoData[graphType].wins / (algoData[graphType].wins + algoData[graphType].losses)) * 100),
                                        },
                                        {
                                            x: `Loses: ${Math.trunc(
                                                (algoData[graphType].losses / (algoData[graphType].wins + algoData[graphType].losses)) * 100,
                                            )}%`,
                                            y: Math.trunc((algoData[graphType].losses / (algoData[graphType].wins + algoData[graphType].losses)) * 100),
                                        },
                                    ]}
                                />
                            )}
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    );
};

export default StatGraphs;
