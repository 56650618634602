// Library imports
import React, { useEffect, useState } from 'react';
import { Form, FormSelect, Nav, Button, Alert, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faList } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import Slider from '@mui/material/Slider';
// Component imports
import PieChart from '../../components/graphs/PieChart';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import TrendList from './TrendList';
import Pagination from '../../components/Pagination';
import DoubleBarChart from '../../components/graphs/DoubleBarChart';
// Styled components
import {
    FilterWrapper,
    GraphWrapper,
    GraphHeaderWrapper,
    TrendDescription,
    TrendDetailsWrapper,
    TrendFilter,
    TrendFilterDescription,
    TrendFilterTitle,
    TrendGraphSubtitle,
    TrendGraphTitle,
    TrendGraphTitleWrapper,
    TrendGraphWrapper,
    TrendTitle,
    TrendsWrapper,
} from './styles';
import { SearchButtonContainer } from '../SearchBuilder/styles';
import { LabelValueListGroup } from '../App/styles';
// Util imports
import { formatOdd, sortGames, capitalize } from '../../utils';
import { GREEN, PRIMARY, RED } from '../../utils/styleConstants';
import { EDITABLE_TRENDS } from '../../utils/constants';
// Action imports
import { fetchTrendData, setAmount, setTrend, setWinnerType } from '../../store/slices/trendBuilderSlice';

const dayMap = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
};

const SingleTrend = () => {
    // Redux
    const { loading, trend, winnerType, error, amount } = useSelector(state => state.trendBuilder);
    const { sport, teams } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [tab, setTab] = useState('trend');
    const [contentView, setContentView] = useState('graph');
    const [sliderValue, setSliderValue] = useState(amount === 'default' ? 0 : amount);
    let params = queryString.parse(window.location.href.split('?')[1]);
    const [activeTeam, setActiveTeam] = useState(params.team ? teams.find(team => team.teamId === Number(params.team)).teamId : teams[0].teamId);
    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * 4;
    const endIndex = startIndex + 4;

    useEffect(() => {
        if (params.trend) {
            // Note When coming from TrendsList we set the dispatch setTrend there so setting here won't cause a re-render
            // TODO this causees a double render when coming from game cards (2 fetchTrendData calls)
            dispatch(setTrend(EDITABLE_TRENDS.find(trend => trend.id === params.trend)));
        }
        // Set to default on the first load so init amount is set from response
        dispatch(setAmount('default'));
        dispatch(fetchTrendData());
    }, [trend.id, sport]);

    useEffect(() => {
        setActiveTeam(params.team ? teams.find(team => team.teamId === Number(params.team)).teamId : teams[0].teamId);
    }, [teams]);

    useEffect(() => {
        // Can't use useMemo because we need to set the slideValue in other places
        // Make sure it's not default because sliderValue must always be a number
        // This is for when the trend changes the amount is set to a number and the slider needs to be updated
        if (amount !== sliderValue && amount !== 'default') {
            setSliderValue(amount);
        }
    }, [amount]);

    const getTeamData = (value, property) => {
        const teamData = teams.find(team => team[property] === value || team.city === value);
        if (!teamData) {
            console.log(`No team data for for ${value} while looking for ${property}`);
            return {};
        } else {
            return teamData;
        }
    };

    const trendGraphTitle = () => {
        if (trend.id === 'team-days-of-week') {
            const activeTeamData = teams.find(team => team.teamId === activeTeam);
            return activeTeamData?.teamName ? `${activeTeamData.teamName} ${winnerType ? `(${capitalize(winnerType)})` : ''}` : '';
        }
        return trend?.title ? trend?.title : '';
    };

    const renderTrendGraph = () => {
        if (!trend?.data) return;
        if (trend.type === 'pie') {
            return (
                <div style={{ height: '350px' }}>
                    <PieChart
                        data={[
                            {
                                x: `Wins: ${Math.trunc((trend.data.wins / trend.data.entries) * 100)}%`,
                                y: (trend.data.wins / trend.data.entries) * 100,
                            },
                            {
                                x: `Losses: ${Math.trunc((trend.data.losses / trend.data.entries) * 100)}%`,
                                y: (trend.data.losses / trend.data.entries) * 100,
                            },
                        ]}
                    />
                </div>
            );
        }
        if (trend.type === 'bar-group') {
            const teamData = trend.data[activeTeam];
            return (
                <DoubleBarChart
                    xAxisLabel="day"
                    yAxisLabel="Win %"
                    keys={[
                        { label: 'Wins', dataValue: 'wins' },
                        { label: 'Losses', dataValue: 'losses' },
                    ]}
                    barWidth={sport === 'mlb' ? 10 : 20}
                    data={Object.keys(teamData[winnerType])
                        .sort((a, b) => {
                            return dayMap[a] - dayMap[b];
                        })
                        .map(day => {
                            return {
                                x: day,
                                wins: teamData[winnerType][day].wins / teamData[winnerType][day].entries,
                                losses: teamData[winnerType][day].losses / teamData[winnerType][day].entries,
                            };
                        })}
                    symbol="%"
                />
            );
        }
        return;
    };

    const renderGameList = () => {
        const listHtml = sortGames(trend.games)
            .slice(startIndex, endIndex)
            .map(game => {
                const awayML = game.awayTeam.odds?.fullGame?.moneyline ? game.awayTeam.odds.fullGame.moneyline : 0;
                const homeML = game.homeTeam.odds?.fullGame?.moneyline ? game.homeTeam.odds.fullGame.moneyline : 0;
                return (
                    <ListGroup.Item
                        style={{
                            borderLeft: `5px solid ${game.trendResult === 'win' ? GREEN : RED}`,
                        }}
                    >
                        <label>
                            <span style={{ display: 'block', fontWeight: '400', fontSize: 12 }}>{game.gameId}</span>
                            <span>
                                {game.awayTeam.teamName} ({formatOdd(awayML, 'moneyline')})
                            </span>
                            <span style={{ margin: '0px 5px' }}>@</span>
                            <span>
                                {game.homeTeam.teamName} ({formatOdd(homeML, 'moneyline')})
                            </span>
                            <span style={{ display: 'block', fontWeight: '400' }}>{moment(new Date(game.dateTime)).format('MM/DD hh:mm A')}</span>
                        </label>
                        <span>
                            Score: {game.awayTeam.score}-{game.homeTeam.score}
                        </span>
                        <span style={{ margin: '0px 15px' }}>Winner: {getTeamData(game.winner, 'teamId').abbr}</span>
                        <span>Spread Winner: {getTeamData(game.spreadWinner, 'teamId').abbr}</span>
                    </ListGroup.Item>
                );
            });
        return (
            <div>
                <Pagination
                    items={trend?.games ? trend.games : []}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    style={{ marginTop: '15px' }}
                    itemsPerPage={4}
                />
                <LabelValueListGroup style={{ marginTop: '15px' }} direction="row" variant="flush">
                    {listHtml}
                </LabelValueListGroup>
            </div>
        );
    };

    return (
        <TrendsWrapper>
            <FilterWrapper>
                <Nav
                    variant="tabs"
                    className="mb-2"
                    activeKey={tab}
                    style={{
                        marginLeft: '-25px',
                        marginRight: '-25px',
                        paddingLeft: '20px',
                        paddingRight: '25px',
                        marginTop: '5px',
                    }}
                    id="change-trend-tabs"
                >
                    <Nav.Item>
                        <Nav.Link onClick={() => setTab('trend')} eventKey="trend">
                            Trend
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => setTab('change-trend')} eventKey="change-trend">
                            Change Trend
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                {tab === 'change-trend' ? (
                    <TrendList />
                ) : (
                    <div>
                        <TrendDetailsWrapper>
                            <TrendTitle>{trend?.title ? trend?.title : ''}</TrendTitle>
                            <TrendDescription>{trend?.description ? trend?.description : ''}</TrendDescription>
                        </TrendDetailsWrapper>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {trend?.winnerTypes ? (
                            <TrendFilter>
                                <TrendFilterTitle>Winner Type</TrendFilterTitle>
                                <FormSelect required style={{ maxWidth: '200px' }} value={winnerType} onChange={e => dispatch(setWinnerType(e.target.value))}>
                                    {trend.winnerTypes.map((option, i) => {
                                        return (
                                            <option key={i} value={option}>
                                                {option}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </TrendFilter>
                        ) : (
                            <></>
                        )}
                        {trend?.filter ? (
                            <TrendFilter>
                                <TrendFilterTitle>{trend.filter.label}</TrendFilterTitle>
                                {trend.filter.input === 'number' ? (
                                    <Slider
                                        value={sliderValue}
                                        onChange={(_, newValue) => setSliderValue(Number(newValue))}
                                        valueLabelDisplay="on"
                                        min={trend.filter.validation === 'positive' ? 100 : -400}
                                        max={trend.filter.validation === 'positive' ? 400 : -100}
                                        valueLabelFormat={value => formatOdd(value, 'moneyline')}
                                        step={1}
                                        sx={{ width: 250, color: PRIMARY }}
                                    />
                                ) : (
                                    <></>
                                )}
                                <TrendFilterDescription>{trend.filter.description}</TrendFilterDescription>
                            </TrendFilter>
                        ) : (
                            <></>
                        )}
                        {trend.id === 'team-days-of-week' ? (
                            <TrendFilter>
                                <TrendFilterTitle>Select Team</TrendFilterTitle>
                                <FormSelect
                                    style={{ maxWidth: '200px', marginRight: 15 }}
                                    value={activeTeam}
                                    onChange={e => setActiveTeam(Number(e.target.value))}
                                >
                                    {teams.map((team, i) => {
                                        return (
                                            <option key={i} value={team.teamId}>
                                                {team.teamName}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </TrendFilter>
                        ) : (
                            <></>
                        )}
                        <SearchButtonContainer>
                            {trend.id !== 'team-days-of-week' ? (
                                <Button
                                    onClick={() => {
                                        dispatch(setAmount(sliderValue));
                                        dispatch(fetchTrendData());
                                    }}
                                    disabled={loading}
                                >
                                    Analyze Trend
                                </Button>
                            ) : (
                                <></>
                            )}
                        </SearchButtonContainer>
                    </div>
                )}
            </FilterWrapper>
            <TrendGraphWrapper>
                <GraphWrapper id="trend-graph-container">
                    <GraphHeaderWrapper>
                        <TrendGraphTitleWrapper>
                            <TrendGraphTitle>{trendGraphTitle()}</TrendGraphTitle>
                            {trend?.data && trend.filter && amount !== 0 ? (
                                <TrendGraphSubtitle>
                                    {trend.filter.label}: {amount}
                                </TrendGraphSubtitle>
                            ) : (
                                <></>
                            )}
                        </TrendGraphTitleWrapper>
                        {trend?.games?.length ? (
                            <Button
                                variant="light"
                                onClick={() => setContentView(contentView === 'graph' ? 'game-list' : 'graph')}
                                id="toggle-content-view-button"
                            >
                                {contentView === 'graph' ? <FontAwesomeIcon icon={faList} /> : <FontAwesomeIcon icon={faChartArea} />}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </GraphHeaderWrapper>
                    {loading ? <LoadingSpinner loading={loading} /> : contentView === 'graph' ? renderTrendGraph() : renderGameList()}
                </GraphWrapper>
            </TrendGraphWrapper>
        </TrendsWrapper>
    );
};

export default SingleTrend;
