// Library imports
import React, { useEffect, useState } from 'react';
import { Card, Container, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Information from './Information';
import Pay from './Pay';
import Access from './Access';
// Utils imports
import { scrollToTop } from '../../utils';
import { PACKAGES } from '../../utils/constants';
// Styled components
import { StageBackButton, StageTitle } from './styles';
// Action imports
import { setData, setStage } from '../../store/slices/registerSlice';

const Register = () => {
    // Redux
    const { data, stage } = useSelector(state => state.register);
    const dispatch = useDispatch();
    // Local state
    const [error, setError] = useState('');

    const stageData = {
        info: {
            title: 'User Information',
            backFunc: () => (window.location.href = '/packages'),
            backTitle: 'Cancel',
        },
        pay: {
            title: 'Payment',
            backFunc: () => {
                setError('');
                dispatch(setStage('info'));
            },
            backTitle: 'Change Info',
        },
        access: {
            title: 'Complete',
            backFunc: () => (window.location.href = '/login'),
            backTitle: 'Login',
        },
    };

    useEffect(() => {
        scrollToTop();
    }, [stage, error]);

    useEffect(() => {
        let params = queryString.parse(window.location.href.split('?')[1]);
        const plan = PACKAGES[params.package] ? { ...PACKAGES[params.package] } : { ...PACKAGES['yearly-access'] };
        dispatch(setData({ plan: { ...plan } }));
    }, []);

    return (
        <div style={{ backgroundColor: '#ededed' }} className="page-wrapper">
            <Helmet>
                <title>Register</title>
                <meta name="description" content="Sign up for your own Line Prophet LLC account and access our entire platform." />
            </Helmet>
            <Header currentPage="packages" lightMode={true} shadow />
            <Container
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '25px 10px',
                    minHeight: '55vh',
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#fdfdfd',
                        padding: 15,
                        maxWidth: '990px',
                        width: '100%',
                    }}
                >
                    <StageBackButton variant="link" onClick={stageData[stage].backFunc}>
                        {stageData[stage].backTitle}
                    </StageBackButton>
                    <StageTitle>{data.plan?.name}</StageTitle>
                    <Card.Subtitle style={{ fontSize: 14, fontWeight: 400 }} className="mb-2 text-muted">
                        {stageData[stage].title}
                    </Card.Subtitle>
                    <Card.Body>
                        <Alert variant="danger" style={{ textAlign: 'center' }} className={`${!error ? 'hide' : ''}`}>
                            {error}
                        </Alert>
                        {stage === 'info' ? (
                            <Information setError={setError} />
                        ) : stage === 'pay' ? (
                            <Pay setError={setError} />
                        ) : stage === 'access' ? (
                            <Access setError={setError} />
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <Footer />
        </div>
    );
};

export default Register;
