// Library imports
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faAppStore, faTwitter } from '@fortawesome/free-brands-svg-icons';
// Component imports
import Header from '../components/Header';
import Footer from '../components/Footer';
import Testimonials from '../components/Testimonials';
import Section from '../components/Section';
import PrimaryButton from '../components/Buttons/PrimaryButton';
// Util imports
import { scrollToTop } from '../utils';
// Images
import headerImage from '../img/logo-icon-white.png';
import twitterIcon from '../img/twitter.png';
// CSS imports
import '../css/Header.css';
import LightButton from '../components/Buttons/LightButton';

const Home = () => {
    useEffect(() => scrollToTop(), []);

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Line Prophet Home</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
            </Helmet>
            <Header currentPage="home" lightMode={false} />
            <Section
                backgroundColor={false}
                lightText={true}
                lightTextDesc={true}
                topLine="Exclusive Access"
                headline="Data, algorithms, & experts focused on winning."
                description={`Our unique system combines data and expert analysis to find winners across the board.  By using algorithmic predictions and expert insights we provide the most advanced sports betting community.`}
                buttons={[
                    <Link to="/packages">
                        <PrimaryButton
                            size="lg"
                            style={{
                                marginRight: 5,
                                fontSize: '1.1rem',
                            }}
                        >
                            <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: 5 }} />
                            Start Winning
                        </PrimaryButton>
                    </Link>,
                    <Link
                        to={{
                            pathname: 'https://apps.apple.com/us/app/line-prophet/id1641926747',
                        }}
                        target="_blank"
                    >
                        <LightButton size="lg" style={{ fontSize: '1.1rem' }}>
                            <FontAwesomeIcon icon={faAppStore} style={{ marginRight: 5 }} />
                            Now on iOS
                        </LightButton>
                    </Link>,
                ]}
                imgStart=""
                img={headerImage}
                slideDirection="img-slide-left"
            />
            <Section
                backgroundColor="white"
                lightText={false}
                lightTextDesc={false}
                topLine="Why choose Line Prophet?"
                headline="Insights that give you an edge."
                description="Empower your sports betting journey with Line Prophet - your comprehensive toolkit for serious enthusiasts. Our cutting-edge data and advanced algorithms provide unparalleled insights, arming you with a clearer perspective on every game."
                buttons={[
                    <Link to="/about">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />
                            Learn More
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart="start"
                contentType="card"
                slideDirection="img-slide-right"
            />
            <Section
                backgroundColor="silver"
                lightText={false}
                lightTextDesc={false}
                topLine="Predictive Algorithm"
                headline={'Algorithmic predictions for every game.'}
                description="Unlock the future of sports outcomes with our algorithmic models, delivering predictive final scores for every game. Our focus on key factors, trends, and unique situations allows us to generate a realistic projection of the ultimate outcome."
                buttons={[
                    <Link to="/about">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />
                            Learn More
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart=""
                contentType="card"
                defaultCardWindow={'prediction'}
                slideDirection="img-slide-left"
            />
            <Section
                backgroundColor="white"
                lightTextDesc={false}
                topLine="Honesty, transparency, results"
                headline="VIP Picks & Transparency."
                description="Explore our VIP Picks and Transparency Drawer, offering a transparent window into historical prediction data. Track the algorithm's success over time to uncover valuable model trends. With Line Prophet, informed decisions are not just a possibility but a result of data-driven insights and a commitment to transparency."
                buttons={[
                    <Link to="/about">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />
                            Learn More
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart="start"
                contentType="vip-stats-yearly"
                slideDirection="img-slide-right"
            />
            <Section
                backgroundColor="silver"
                lightText={false}
                lightTextDesc={false}
                topLine="Follow us on Twitter?"
                headline="Follow our Twitter page for news and updates."
                description="Stay in the loop with Line Prophet by joining us on Twitter. Catch the latest updates on platform features, API enhancements, and even occasional algorithm-generated predictions. Follow us to ensure you never miss a beat in the dynamic world of Line Prophet."
                buttons={[
                    <Link to={{ pathname: 'https://twitter.com/LineProphetLLC' }} target="_blank">
                        <PrimaryButton>
                            <FontAwesomeIcon icon={faTwitter} style={{ marginRight: 5 }} />
                            Twitter
                        </PrimaryButton>
                    </Link>,
                ]}
                imgStart=""
                img={twitterIcon}
                slideDirection="img-slide-left"
            />
            <Testimonials bgColor="white" />
            <Footer />
        </div>
    );
};

export default Home;
