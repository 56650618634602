// Library imports
import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faMicroscope } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
// Component imports
import SingleTrend from './SingleTrend';
import AllTrends from './AllTrends';
import NoData from '../../components/NoData';
// Styled components
import { TrendsWrapper } from './styles';
// Hook imports
import useScreenWidth from '../../utils/hooks/useScreenWidth';
// Util imports
import { ADVANCED_SEARCH_SCREEN_SIZE } from '../../utils/constants';

const Trends = () => {
    const history = useHistory();
    // Local state
    const [subpage, setSubpage] = useState('single-trend');
    // Hooks
    const windowWidth = useScreenWidth();

    if (windowWidth < ADVANCED_SEARCH_SCREEN_SIZE) {
        return (
            <TrendsWrapper>
                <NoData
                    title={'Please try again on a desktop.'}
                    message={'You must be on a desktop device to use this feature.'}
                    variant="danger"
                    containerStyle={{ margin: '25px auto', maxWidth: '90%' }}
                />
            </TrendsWrapper>
        );
    }

    return (
        <div>
            <Navbar bg="light" variant="light" style={{ border: '1px solid #ccc' }}>
                <Container>
                    <Nav className="justify-content-center w-100">
                        <Nav.Link
                            active={subpage === 'single-trend'}
                            onClick={() => {
                                history.push({
                                    pathname: location.pathname,
                                    search: '?subpage=trends',
                                });
                                setSubpage('single-trend');
                            }}
                            style={{ marginRight: 15 }}
                        >
                            <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faMicroscope} /> Analyze Trend
                        </Nav.Link>
                        <Nav.Link
                            active={subpage === 'all-trends'}
                            onClick={() => {
                                history.push({
                                    pathname: location.pathname,
                                    search: '?subpage=trends',
                                });
                                setSubpage('all-trends');
                            }}
                            style={{ marginRight: 15 }}
                        >
                            <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faChartArea} /> All Trends
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            {subpage === 'single-trend' ? <SingleTrend /> : <AllTrends />}
        </div>
    );
};

export default Trends;
