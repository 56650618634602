// Library imports
import React from 'react';
import { Cell } from 'rsuite-table';
// Util imports
import { addPlusSign, formatOddButtonJuice } from '../../../utils';
// Style imports
import { OddButton } from '../../../app_pages/GameCards/game_card_windows/PickOdds/styles';

export const PropValue = ({ rowData, dataKey, handleSavePick, ...props }) => {
    const colText = rowData[dataKey];
    const colArr = colText ? colText.split('\n') : ['NA', 'NA'];
    const propData = rowData['propData'];
    const playerData = rowData['playerData'];
    const gameId = rowData['gameId'];
    const gameTime = rowData['gameTime'];
    const oddType = dataKey === 'prop-over' ? 'over' : dataKey === 'prop-under' ? 'under' : '';
    return (
        <Cell {...props}>
            <OddButton onClick={() => handleSavePick(propData, playerData, gameId, gameTime, oddType, Number(colArr[1]))}>
                <span className="value">
                    {dataKey === 'prop-over' ? 'o' : dataKey === 'prop-under' ? 'u' : ''}
                    {dataKey === 'prop-value' ? addPlusSign(colArr[0]) : colArr[0]}
                </span>
                <span className="juice">{formatOddButtonJuice(colArr[1], dataKey)}</span>
            </OddButton>
        </Cell>
    );
};
