// Library imports
import React from 'react';
import { Container, Button } from 'react-floating-action-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
// Util imports
import { scrollToTop } from '../../utils';
// Hooks
import useScreenWidth from '../../utils/hooks/useScreenWidth';
import useScrollPosition from '../../utils/hooks/useScrollPosition';
// Constants
import { MOBILE_SCREEN_SIZE } from '../../utils/constants';
// CSS imports
import './style.css';

const BackToTop = () => {
    const screenWidth = useScreenWidth();
    const scrollPosition = useScrollPosition();

    if (screenWidth > MOBILE_SCREEN_SIZE || scrollPosition <= 150) return <></>;

    return (
        <Container>
            <Button
                onClick={() => scrollToTop()}
                styles={{
                    borderRadius: '50px',
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    color: '#fff',
                }}
            >
                <FontAwesomeIcon icon={faArrowUp} />
            </Button>
        </Container>
    );
};

export default BackToTop;
