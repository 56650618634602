// Library imports
import React, { useState } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import Dropdown from '../../../components/DropDown';
import { useDispatch, useSelector } from 'react-redux';
// Controller imports
import { addPick } from '../../../controllers/UserController';
// Util imports
import { createPickId, capitalize, convertToISOString } from '../../../utils';
import { PICK_OBJ } from '../../../utils/constants';
// Action imports
import { setUser } from '../../../store/slices/userSlice';
import { ProfileContentContainer } from '../styles';

const CreatePick = () => {
    // Redux
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    // Local state
    const [loading, setLoading] = useState(false);
    const [pickData, setPickData] = useState({
        ...PICK_OBJ,
        sport: 'mlb',
        pickType: 'spread',
    });
    // Utils functions
    const resetPickData = () => setPickData({ ...PICK_OBJ, sport: 'mlb', pickType: 'spread' });

    const handleAddPick = async () => {
        try {
            setLoading(true);
            const newPicks = await addPick({
                ...pickData,
                gameTime: convertToISOString(pickData.gameTime),
                gameId: '123',
                pickTeamId: 123,
                userId: user.uid,
                pickId: createPickId(),
            });
            dispatch(setUser({ ...user, picks: newPicks }));
            resetPickData();
        } catch (error) {
            console.log(`Error saving pick.\n${error}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ProfileContentContainer>
            <Card>
                <Card.Body>
                    <Form style={{ maxWidth: 800 }}>
                        <Row>
                            <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                Sport
                            </Form.Label>
                            <Col>
                                <Dropdown
                                    variant={'light'}
                                    dropdownStyle={{ border: '1px solid #ddd' }}
                                    value={pickData.sport}
                                    setValue={selectedKey =>
                                        setPickData({
                                            ...pickData,
                                            sport: selectedKey,
                                        })
                                    }
                                    menuItems={['mlb', 'nhl', 'cfb', 'nfl', 'nba', 'cbb', 'ncaa-baseball'].map(sport => sport)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                Type
                            </Form.Label>
                            <Col>
                                <Dropdown
                                    variant={'light'}
                                    dropdownStyle={{ border: '1px solid #ddd' }}
                                    value={pickData.pickType}
                                    setValue={selectedKey =>
                                        setPickData({
                                            ...pickData,
                                            pickType: selectedKey,
                                        })
                                    }
                                    menuItems={['spread', 'moneyline', 'over', 'under', 'parlay', 'teaser', 'prop'].map(type => type)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                {pickData.pickType === 'prop'
                                    ? 'Prop + Odds'
                                    : pickData.pickType === 'parlay' || pickData.pickType === 'teaser'
                                    ? 'Teams + Odds'
                                    : 'Team'}
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter a team or player name..."
                                    value={pickData.teamName}
                                    onChange={e =>
                                        setPickData({
                                            ...pickData,
                                            teamName: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        {pickData.pickType !== 'prop' && pickData.pickType !== 'parlay' && pickData.pickType !== 'teaser' ? (
                            <Row className="mt-3">
                                <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                    {capitalize(pickData.pickType)}
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        value={pickData.pickValue}
                                        onChange={e =>
                                            setPickData({
                                                ...pickData,
                                                pickValue: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}
                        {pickData.pickType !== 'moneyline' ? (
                            <Row className="mt-3">
                                <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                    Juice
                                </Form.Label>
                                <Col>
                                    <Form.Control
                                        type="number"
                                        value={pickData.pickJuice}
                                        onChange={e =>
                                            setPickData({
                                                ...pickData,
                                                pickJuice: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}
                        <Row className="mt-3">
                            <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                Units
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="number"
                                    value={pickData.units}
                                    onChange={e =>
                                        setPickData({
                                            ...pickData,
                                            units: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                Analysis
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter a play analysis..."
                                    value={pickData.analysis}
                                    maxLength={75}
                                    onChange={e =>
                                        setPickData({
                                            ...pickData,
                                            analysis: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Form.Label column lg={2} style={{ fontWeight: 'bold' }}>
                                Game Date
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    type="datetime-local"
                                    value={pickData.gameTime}
                                    onChange={e => {
                                        setPickData({
                                            ...pickData,
                                            gameTime: e.target.value, // TODO make sure this works
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Form.Label column lg={2} style={{ fontWeight: 'bold' }}></Form.Label>
                            <Col>
                                <Button disabled={loading} variant="success" className="mt-3" style={{ width: '100%' }} onClick={handleAddPick}>
                                    Save Pick
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </ProfileContentContainer>
    );
};

export default CreatePick;
