// Library imports
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button, ListGroup, ListGroupItem, Badge, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// Component imports
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Testimonials from '../../components/Testimonials';
// Util imports
import { scrollToTop } from '../../utils';
import { PACKAGES } from '../../utils/constants';
// CSS imports
import '../../css/Packages.css';

const Packages = () => {
    const [packageKey, setPackageKey] = useState('access');
    const [featuresKey, setFeaturesKey] = useState('account');
    const testRef = useRef(null);

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div className="page-wrapper">
            <Header currentPage="packages" lightMode={true} shadow />
            <Container>
                <Row style={{ padding: '150px 0px' }}>
                    <Col className="m-auto" sm={12} md={12} lg={12} xl={12}>
                        <div className="section-title">
                            <h1 className="heading text-lg-center" style={{ color: 'rgb(51, 51, 51)' }}>
                                Package Options
                            </h1>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={12} xl={12}>
                        <Tabs style={{ justifyContent: 'center' }} activeKey={packageKey} onSelect={k => setPackageKey(k)}>
                            <Tab eventKey="access" title="Access Packages">
                                <Row className="mt-3">
                                    <Col>
                                        <p
                                            style={{
                                                fontSize: '1.25rem',
                                                letterSpacing: '-.025em',
                                                lineHeight: '1.75rem',
                                            }}
                                            className="text-lg-center"
                                        >
                                            This package provides you with an exclusive Line Prophet account, granting you personal access to our cutting-edge
                                            system. Alongside this, you'll gain entry to our VIP Twitter page, where real-time insights and updates come alive.
                                            Connect with a like-minded community on our private Discord server, where you can share your experiences and
                                            strategies.
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    {/*<Col style={{ marginTop: '15px' }} sm={12} md={12} lg={6} xl={6}>
                                        <Card className='shadow'>
                                            <Card.Body>
                                                <Card.Title className='strong-header'>Access Package (Free)</Card.Title>
                                                <ListGroup variant='flush'>
                                                    <ListGroupItem><FontAwesomeIcon icon={faCheckCircle} />Line Prophet account & matchup data</ListGroupItem>
                                                    <ListGroupItem><FontAwesomeIcon icon={faCheckCircle} />Advanced Query Builder</ListGroupItem>
                                                    <ListGroupItem><FontAwesomeIcon icon={faTimesCircle} />Algorithmic predictions for every game</ListGroupItem>
                                                    <ListGroupItem><FontAwesomeIcon icon={faTimesCircle} />Expert picks posted daily</ListGroupItem>
                                                    <ListGroupItem><FontAwesomeIcon icon={faTimesCircle} />Community Discord access</ListGroupItem>
                                                    <ListGroupItem><FontAwesomeIcon icon={faTimesCircle} />24/7 selection assistance</ListGroupItem>
                                                </ListGroup>
                                                <div style={{ padding: '15px 10px' }}>
                                                    <Card.Text className='package-price'>{`Free`}</Card.Text>
                                                    <Button
                                                        onClick={() => window.location = '/register?package=free-access'}
                                                        variant="success"
                                                    >
                                                        Create Account
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>*/}
                                    {Object.values(PACKAGES)
                                        .filter(packageObj => packageObj.type === 'account')
                                        .map(packageObj => {
                                            return (
                                                <Col
                                                    style={{
                                                        marginTop: '15px',
                                                    }}
                                                    sm={12}
                                                    md={12}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Card className="shadow">
                                                        <Card.Body>
                                                            <Card.Title className="strong-header">{packageObj.name}</Card.Title>
                                                            <ListGroup variant="flush">
                                                                <ListGroupItem>
                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                    Line Prophet web, iOS & Android application access
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                    Algorithmic predictions for every game
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                    Expert picks posted daily
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                    Advanced Query Builder
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                    Community Discord access
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                    24/7 selection assistance
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                            <div
                                                                style={{
                                                                    padding: '15px 10px',
                                                                }}
                                                            >
                                                                <Card.Text className="package-price">{`$${packageObj.price}.00/${packageObj.timeFrame}`}</Card.Text>
                                                                <Button
                                                                    onClick={() => (window.location = `/register?package=${packageObj.id}`)}
                                                                    variant="success"
                                                                >
                                                                    Purchase Package
                                                                </Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </Tab>
                            <Tab eventKey="pick" title="Pick Packages">
                                <Row className="mt-3">
                                    <Col>
                                        <p
                                            style={{
                                                fontSize: '1.25rem',
                                                letterSpacing: '-.025em',
                                                lineHeight: '1.75rem',
                                            }}
                                            className="text-lg-center"
                                        >
                                            If you prefer a more hands-off approach, our Pick Package is perfect for you. This package delivers
                                            algorithm-generated plays, carefully selected by our expert team. These winning plays will be conveniently sent to
                                            your inbox via email, ensuring you never miss out on an opportunity to score big.
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col style={{ marginTop: '15px' }} sm={12} md={12} lg={6} xl={6}>
                                        <Card className="shadow">
                                            <Card.Body>
                                                <Card.Title className="strong-header">Play of the Day</Card.Title>
                                                <ListGroup variant="flush">
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Get a our very best play
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Must win or a week free
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Delivered via email
                                                    </ListGroupItem>
                                                </ListGroup>
                                                <div
                                                    style={{
                                                        padding: '15px 10px',
                                                    }}
                                                >
                                                    <Card.Text className="package-price">{`$${PACKAGES['play-of-day'].price}.00`}</Card.Text>
                                                    <Button onClick={() => (window.location = '/register?package=play-of-day')} variant="success">
                                                        Purchase Package
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col style={{ marginTop: '15px' }} sm={12} md={12} lg={6} xl={6}>
                                        <Card className="shadow">
                                            <Card.Body>
                                                <Card.Title className="strong-header">Daily Pick Package</Card.Title>
                                                <ListGroup variant="flush">
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        24 hours worth of plays in all major sports
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Profit or your money back
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Delivered via email
                                                    </ListGroupItem>
                                                </ListGroup>
                                                <div
                                                    style={{
                                                        padding: '15px 10px',
                                                    }}
                                                >
                                                    <Card.Text className="package-price">{`$${PACKAGES['daily'].price}.00`}</Card.Text>
                                                    <Button onClick={() => (window.location = '/register?package=daily')} variant="success">
                                                        Purchase Package
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col style={{ marginTop: '15px' }} sm={12} md={12} lg={6} xl={6}>
                                        <Card className="shadow">
                                            <Card.Body>
                                                <Card.Title className="strong-header">50 Pick Package</Card.Title>
                                                <ListGroup variant="flush">
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Expert plays delievered daily
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Get 50 plays delivered to your email
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                        Request up to 3 custom algorithm plays a day
                                                    </ListGroupItem>
                                                </ListGroup>
                                                <div
                                                    style={{
                                                        padding: '15px 10px',
                                                    }}
                                                >
                                                    <Card.Text className="package-price">{`$${PACKAGES['5050'].price}.00`}</Card.Text>
                                                    <Button onClick={() => (window.location = '/register?package=5050')} variant="success">
                                                        Purchase Package
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
            <div style={{ background: '#f5f5f5' }}>
                <Container>
                    <Row
                        style={{
                            padding: '150px 0px',
                            flexWrap: 'wrap-reverse',
                        }}
                    >
                        <Col className="marginTopSmall-5" sm={12} md={6}>
                            <ListGroup style={{ padding: '0px 15px' }} variant="flush">
                                <ListGroup.Item style={{ background: 'transparent' }} className="d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                            }}
                                        >
                                            Select Package
                                        </div>
                                        <p style={{ marginTop: '10px' }}>
                                            An Access Package includes your own account to the Line Prophet system and community. A Pick Package will get you
                                            plays hand selected by our team and delivered to you via email.
                                        </p>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item style={{ background: 'transparent' }} className="d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                            }}
                                        >
                                            Create Account
                                        </div>
                                        <p style={{ marginTop: '10px' }}>
                                            Your account will give you access to everything in the Line Prophet system. You will use this account to log in to
                                            all of the different Line Prophet services and platforms.
                                        </p>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item style={{ background: 'transparent' }} className="d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                            }}
                                        >
                                            Log in
                                        </div>
                                        <p style={{ marginTop: '10px' }}>
                                            After the payment process is complete, follow the link to log in to the Line Prophet system! Be sure to request a
                                            follow on Twitter to @LineProphetVIP, and join the Discord server located in the pinned tweet on that account.
                                        </p>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={12} md={6}>
                            <h1 style={{ color: 'rgb(51, 51, 51)' }} className="heading">
                                How does it work?
                            </h1>
                            <p
                                style={{
                                    fontSize: '1.25rem',
                                    letterSpacing: '-.025em',
                                    lineHeight: '1.75rem',
                                }}
                            >
                                Signing up for a LineProphet.com package is a breeze, requiring just three easy steps! Follow the straightforward instructions
                                in this section, and you'll be on your way to winning in no time.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row style={{ padding: '150px 0px' }}>
                        <Col className="m-auto" sm={12} md={6}>
                            <h1
                                style={{
                                    width: '100%',
                                    color: 'rgb(51, 51, 51)',
                                }}
                                className="heading"
                            >
                                Access Features
                            </h1>
                            <p
                                style={{
                                    fontSize: '1.25rem',
                                    letterSpacing: '-.025em',
                                    lineHeight: '1.75rem',
                                }}
                            >
                                Learn more about all of the services an Access Package provides. Getting your own account to the platform is just the beginning.
                                Membership not only gives you access to the platform, but also includes invites to ou Discord community and VIP Twitter feed.
                            </p>
                        </Col>
                        <Col className="mt-3" sm={12} md={6}>
                            <Tabs activeKey={featuresKey} onSelect={k => setFeaturesKey(k)}>
                                <Tab eventKey="account" title="App Access">
                                    <Card
                                        style={{
                                            borderTopWidth: 0,
                                            borderRadius: 0,
                                        }}
                                    >
                                        <Card.Body>
                                            <Card.Title>Web & Mobile App</Card.Title>
                                            <Card.Text>
                                                Your all access account opens the door to our platform, right here at LineProphet.com. Use it to explore
                                                algorithmic predictions, head-to-head data, and trends for every single matchup. But that's not all - your
                                                account also includes valuable features like pick tracking and unique insights into your sports betting results.
                                                Discover your ROI, identify your most profitable teams, and so much more, all at your fingertips! 🚀📊💰
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                                <Tab eventKey="twitter" title="Private Twitter">
                                    <Card
                                        style={{
                                            borderTopWidth: 0,
                                            borderRadius: 0,
                                        }}
                                    >
                                        <Card.Body>
                                            <Card.Title>VIP Twitter Access</Card.Title>
                                            <Card.Text>
                                                Every all access account comes with an exclusive invitation to our private VIP Twitter page. Here, our experts
                                                meticulously hand-select plays daily from the algorithm-generated plays displayed on the platform. These plays
                                                are not only accessible on the web app with your account but are also communicated through our @LineProphetVIP
                                                Twitter page daily. Stay in the loop with other general announcements and engage in discussions on Twitter as
                                                well!
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                                <Tab eventKey="discord" title="Discord Community">
                                    <Card
                                        style={{
                                            borderTopWidth: 0,
                                            borderRadius: 0,
                                        }}
                                    >
                                        <Card.Body>
                                            <Card.Title>Join our Discord Server</Card.Title>
                                            <Card.Text>
                                                This is where the Line Prophet LLC community unites to engage in lively discussions about all things sports
                                                betting. Join in for real-time conversations about ongoing games, stay updated on site changes, and immerse
                                                yourself in a world of sports insights and more!
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div ref={testRef}>
                <Testimonials />
            </div>
            <Footer />
        </div>
    );
};

export default Packages;
