// Library imports
import React, { useState } from 'react';
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const EditGame = ({ game, handleBack, handleSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ ...game });

    const handleSubmit = async () => {
        if (!formData.winner || !formData.spreadWinner) {
            return alert('Please fill out all form fields');
        }
        setLoading(true);
        await handleSave(formData);
        setLoading(false);
    };

    return (
        <div>
            <Card style={{ padding: 15 }}>
                <div
                    style={{
                        padding: 10,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    <div>
                        <Card.Title>{formData.gameId}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted d-flex align-items-center">
                            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faCalendar} /> {moment(game.dateTime).format('MM/DD/YYYY hh:mm A')}
                        </Card.Subtitle>
                    </div>
                    <Button variant="outline-danger" onClick={handleBack} style={{ marginLeft: '50px' }}>
                        Back
                    </Button>
                    <Button variant="success" onClick={handleSubmit} disabled={loading} style={{ marginLeft: '10px' }}>
                        Submit
                    </Button>
                </div>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col></Col>
                            <Col style={{ color: game.awayTeam.color, fontWeight: 'bold', fontSize: 16 }}>{game.awayTeam.teamName}</Col>
                            <Col style={{ color: game.homeTeam.color, fontWeight: 'bold', fontSize: 16 }}>{game.homeTeam.teamName}</Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                            <Col style={{ fontWeight: 'bold' }}>Score</Col>
                            <Col>
                                <Form.Control
                                    required
                                    type="number"
                                    value={formData.awayTeam.score}
                                    onChange={e => setFormData({ ...formData, awayTeam: { ...formData.awayTeam, score: Number(e.target.value) } })}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    required
                                    type="number"
                                    value={formData.homeTeam.score}
                                    onChange={e => setFormData({ ...formData, homeTeam: { ...formData.homeTeam, score: Number(e.target.value) } })}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                            <Col style={{ fontWeight: 'bold' }}>Moneyline</Col>
                            <Col>
                                <Form.Control
                                    required
                                    type="number"
                                    value={formData.awayTeam.odds?.fullGame?.moneyline ? formData.awayTeam.odds?.fullGame?.moneyline : 0}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            awayTeam: {
                                                ...formData.awayTeam,
                                                odds: {
                                                    ...formData.awayTeam.odds,
                                                    fullGame: {
                                                        ...formData.awayTeam.odds?.fullGame,
                                                        moneyline: Number(e.target.value),
                                                    },
                                                },
                                            },
                                        })
                                    }
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    required
                                    type="number"
                                    value={formData.homeTeam.odds?.fullGame?.moneyline ? formData.homeTeam.odds?.fullGame?.moneyline : 0}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            homeTeam: {
                                                ...formData.homeTeam,
                                                odds: {
                                                    ...formData.homeTeam.odds,
                                                    fullGame: {
                                                        ...formData.homeTeam.odds?.fullGame,
                                                        moneyline: Number(e.target.value),
                                                    },
                                                },
                                            },
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                            <Col style={{ fontWeight: 'bold' }}>Spread</Col>
                            <Col>
                                <Form.Control
                                    required
                                    type="number"
                                    value={formData.awayTeam.odds?.fullGame?.spread ? formData.awayTeam.odds?.fullGame?.spread : 0}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            awayTeam: {
                                                ...formData.awayTeam,
                                                odds: {
                                                    ...formData.awayTeam.odds,
                                                    fullGame: {
                                                        ...formData.awayTeam.odds?.fullGame,
                                                        spread: Number(e.target.value),
                                                    },
                                                },
                                            },
                                        })
                                    }
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    required
                                    type="number"
                                    value={formData.homeTeam.odds?.fullGame?.spread ? formData.homeTeam.odds?.fullGame?.spread : 0}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            homeTeam: {
                                                ...formData.homeTeam,
                                                odds: {
                                                    ...formData.homeTeam.odds,
                                                    fullGame: {
                                                        ...formData.homeTeam.odds?.fullGame,
                                                        spread: Number(e.target.value),
                                                    },
                                                },
                                            },
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col style={{ fontWeight: 'bold' }}>Winner</Col>
                            <Col>
                                <Form.Check
                                    checked={formData.winner === game.awayTeam.teamId ? true : false}
                                    onChange={() =>
                                        setFormData({
                                            ...formData,
                                            winner: game.awayTeam.teamId,
                                        })
                                    }
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    checked={formData.winner === game.homeTeam.teamId ? true : false}
                                    onChange={() =>
                                        setFormData({
                                            ...formData,
                                            winner: game.homeTeam.teamId,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col style={{ fontWeight: 'bold' }}>Spread Winner</Col>
                            <Col>
                                <Form.Check
                                    checked={formData.spreadWinner === game.awayTeam.teamId ? true : false}
                                    onChange={() =>
                                        setFormData({
                                            ...formData,
                                            spreadWinner: game.awayTeam.teamId,
                                        })
                                    }
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    checked={formData.spreadWinner === game.homeTeam.teamId ? true : false}
                                    onChange={() =>
                                        setFormData({
                                            ...formData,
                                            spreadWinner: game.homeTeam.teamId,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default EditGame;
