// Library imports
import React, { useState } from 'react';
import { Nav, Pagination } from 'react-bootstrap';
import { Table as RTable, Column, HeaderCell, Cell } from 'rsuite-table';
// Component imports
import { ColoredCell, DeleteCell, EditCell, TeamCell } from './cells/GenericCells';
import { OddValueCell, PlayCell, ResultCell } from './cells/ProfilePickCells';
import { ScheduleGameCell, ScheduleResultCell } from './cells/ScheduleCells';
import { PropValue } from './cells/PropCells';
// Util imports
import { capitalize } from '../../utils';
// CSS imports
import 'rsuite-table/dist/css/rsuite-table.css';

const Table = ({
    dataList = [],
    columns,
    handleSavePick = () => '',
    handleDeleteRow = () => '',
    handleEditRow = () => '',
    tabs = {},
    containerStyle = {},
    pagination = {},
    ...restProps
}) => {
    const [page, setPage] = useState(0);
    const numberOfItemsPerPage = 10;
    const numberOfPages = Math.ceil(dataList.length / numberOfItemsPerPage);
    const from = pagination.enabled ? page * numberOfItemsPerPage : 0;
    const to = pagination.enabled ? Math.min((page + 1) * numberOfItemsPerPage, dataList.length) : dataList.length;

    const generateCustomCell = (dataKey, coloredCell) => {
        // Profile Pick Cells
        if (dataKey === 'play') return <PlayCell dataKey={dataKey} />;
        if (dataKey === 'result') return <ResultCell dataKey={dataKey} handleSavePick={handleSavePick} />;
        if (dataKey === 'odd-value') return <OddValueCell dataKey={dataKey} />;
        // Gamecard Schedule Cells
        if (dataKey === 'schedule-game') return <ScheduleGameCell dataKey={dataKey} />;
        if (dataKey === 'schedule-result') return <ScheduleResultCell dataKey={dataKey} />;
        // Generic Cells
        if (dataKey === 'team') return <TeamCell dataKey={dataKey} />;
        if (dataKey === 'delete') return <DeleteCell dataKey={dataKey} handleDeleteRow={handleDeleteRow} />;
        if (dataKey === 'edit') return <EditCell dataKey={dataKey} handleEditRow={handleEditRow} />;
        if (coloredCell) return <ColoredCell dataKey={dataKey} />;
        // Prop Cells
        if (dataKey === 'prop-value' || dataKey === 'prop-over' || dataKey === 'prop-under')
            return <PropValue handleSavePick={handleSavePick} dataKey={dataKey} />;
        // Default Cells
        return <Cell dataKey={dataKey} />;
    };

    const generatePageItems = () => {
        const pageItems = [];
        for (let i = 0; i <= numberOfPages; i++) {
            pageItems.push(
                <Pagination.Item onClick={() => setPage(i)} active={page === i}>
                    {i + 1}
                </Pagination.Item>,
            );
        }
        return pageItems;
    };

    return (
        <div style={containerStyle}>
            {Object.keys(tabs).length ? (
                <Nav variant="tabs" activeKey={tabs.activeKey}>
                    {tabs.keys.map((key, i) => {
                        return (
                            <Nav.Item key={i}>
                                <Nav.Link eventKey={key} onClick={() => tabs.handleOnNavClick(key)}>
                                    {capitalize(key)}
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
                </Nav>
            ) : (
                <></>
            )}
            <RTable wordWrap={true} data={dataList.slice(from, to)} {...restProps}>
                {columns.map((column, i) => {
                    const { header, dataKey, coloredCell, ...columnProps } = column;
                    return (
                        <Column key={dataKey} {...columnProps}>
                            <HeaderCell>{header}</HeaderCell>
                            {generateCustomCell(dataKey, coloredCell)}
                        </Column>
                    );
                })}
            </RTable>
            {pagination.enabled ? (
                <Pagination style={{ marginTop: '15px', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Pagination.First onClick={() => setPage(0)} />
                    <Pagination.Prev
                        onClick={() => {
                            if (page !== 0) setPage(page - 1);
                        }}
                    />
                    {generatePageItems()}
                    <Pagination.Next
                        onClick={() => {
                            if (page !== numberOfPages) setPage(page + 1);
                        }}
                    />
                    <Pagination.Last onClick={() => setPage(numberOfPages)} />
                </Pagination>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Table;
