// Library imports
import React from 'react';
// Util imports
import { PRIMARY, WHITE } from '../../utils/styleConstants';
// Styled components
import { StyledButton } from './styles';

const LightButton = ({ style, children, hover = true, ...restOfProps }) => {
    return (
        <StyledButton
            id="light-button"
            style={{
                background: WHITE,
                borderColor: WHITE,
                color: PRIMARY,
                ...style,
            }}
            hover={hover}
            {...restOfProps}
        >
            {children}
        </StyledButton>
    );
};

export default LightButton;
