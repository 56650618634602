// Library imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Controller imports
import { getTrendById } from '../../controllers/TrendsController';
// Util imports
import { EDITABLE_TRENDS } from '../../utils/constants';

const initialState = {
    loading: true,
    error: '',
    trend: EDITABLE_TRENDS[0],
    amount: 'default',
    winnerType: '',
};

export const fetchTrendData = createAsyncThunk('trendBuilder/fetchTrendData', async (_, { getState, rejectWithValue }) => {
    try {
        const { trendBuilder, app } = getState();
        const { amount, trend } = trendBuilder;
        const { sport } = app;
        if (amount !== 'default') {
            if (isNaN(amount) || !amount || amount === '-' || amount === '.') {
                throw new Error('Amount must be a number');
            }
            if (trend.filter.validation === 'positive' && parseFloat(amount) < 100) {
                throw new Error('Amount must be a positive number greater than 100');
            }
            if (trend.filter.validation === 'negative' && parseFloat(amount) > -100) {
                throw new Error('Amount must be a positive number less than -100');
            }
        }
        const res = await getTrendById(sport, trend.id, amount);
        return res;
    } catch (error) {
        console.log(`Error getting single trend data.\n${error.message}`);
        return rejectWithValue(error.message);
    }
});

const trendBuilderSlice = createSlice({
    name: 'trendBuilder',
    initialState,
    reducers: {
        setWinnerType: (state, { payload }) => {
            state.winnerType = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setAmount: (state, { payload }) => {
            state.amount = payload;
        },
        setTrend: (state, { payload }) => {
            state.trend = payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTrendData.pending, state => {
            console.log('--- Fetching trend data ----');
            state.loading = true;
            state.error = '';
        });
        builder.addCase(fetchTrendData.fulfilled, (state, { payload }) => {
            if (state.amount === 'default') {
                const defaultAmount = payload.filter ? payload.filter.value : 0;
                state.amount = defaultAmount;
            }
            state.trend = payload;
            state.winnerType = payload.winnerTypes[0];
            state.loading = false;
        });
        builder.addCase(fetchTrendData.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
    },
});

export const { setWinnerType, setError, setAmount, setTrend } = trendBuilderSlice.actions;

export default trendBuilderSlice.reducer;
