// Library imports
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
// Component imports
import GameSearchFilter from '../../components/SearchFilters/GameSearchFilter';
import GameCard from './GameCard';
import NoData from '../../components/NoData';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import BackToTop from '../../components/BackToTop';
// Controller imports
import { pickRecord } from '../../controllers/UserController';
// CSS imports
import '../../css/GameCard.css';
// Util imports
import { convertToISOString } from '../../utils';

Array.prototype.hasMin = function (attrib) {
    return (
        (this.length &&
            this.reduce(function (prev, curr) {
                return prev[attrib] < curr[attrib] ? prev : curr;
            })) ||
        null
    );
};

const GameCards = ({ setSubpage }) => {
    // Redux
    const { filters, sport, gameData, loaders } = useSelector(state => state.app);
    // Local state
    const [value, setValue] = useState('');
    const [activeDate, setActiveDate] = useState(moment().toISOString());
    const [ratingFilter, setRatingFilter] = useState(0);
    const [vipPicks, setVIPPicks] = useState([]);

    useEffect(() => {
        setActiveDate(gameData?.length ? moment(gameData.hasMin('dateTime').dateTime) : moment().toISOString());
    }, [gameData]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await pickRecord('week', true);
                setVIPPicks(res.picks);
            } catch (error) {
                console.log(`Error getting VIP picks (GameCards)\n${error}`);
            }
        }
        fetchData();
    }, []);

    const processGameCards = () => {
        const filterArr = gameData.filter(game => {
            // Check if game is on the active date
            if (!moment(game.dateTime).isSame(convertToISOString(activeDate), 'D')) {
                return false;
            }
            // Check if game is included in the users' set filters
            var inFilter = true;
            if (filters.length !== 0) {
                inFilter = false;
                filters.forEach(filter => {
                    if (filter === 'power-rank' && game.prediction) {
                        if (Math.abs(game.homeTeam.odds.fullGame?.spread - (game.prediction.awayScore - game.prediction.homeScore)) > 5) {
                            inFilter = true;
                        }
                    } else if (filter === 'road-dogs') {
                        inFilter = game.awayTeam.odds?.fullGame?.spread > 0 && game.awayTeam.odds?.fullGame?.spread <= 3;
                    } else if (filter === 'road-favorites') {
                        inFilter = game.awayTeam.odds?.fullGame.moneyline < 0;
                    } else if (filter === 'home-favs') {
                        inFilter = game.homeTeam.odds?.fullGame?.moneyline < -300 || game.homeTeam.odds?.fullGame?.spread < -10;
                    } else if (filter === 'division-game') {
                        inFilter = game.situations['division-game'];
                    } else if (filter === 'conference-game') {
                        inFilter = game.situations['conference-game'];
                    } else if (filter === 'Upcoming') {
                        inFilter = game.status === 'not started';
                    } else if (filter === 'Live') {
                        inFilter = game.status === 'live';
                    } else if (game.advantages.find(adv => adv.id === filter)) {
                        inFilter = true;
                    }
                });
                if (inFilter === false) return false;
            }
            // Check if game is included in the query (search) term
            if (!value) return true;
            if (
                game.awayTeam.teamName.toLowerCase().includes(value.toLowerCase()) ||
                game.homeTeam.teamName.toLowerCase().includes(value.toLowerCase()) ||
                (game.awayTeam.displayName && game.awayTeam.displayName.toLowerCase().includes(value.toLowerCase())) ||
                (game.homeTeam.displayName && game.homeTeam.displayName.toLowerCase().includes(value.toLowerCase())) ||
                game.awayTeam.abbr.toLowerCase().includes(value.toLowerCase()) ||
                game.homeTeam.abbr.toLowerCase().includes(value.toLowerCase()) ||
                game.awayTeam.fullName.toLowerCase().includes(value.toLowerCase()) ||
                game.homeTeam.fullName.toLowerCase().includes(value.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        });
        if (filterArr.length) {
            return filterArr.map(game => {
                const isVIPPlay = vipPicks.find(pick => {
                    return (
                        moment(pick.gameTime).isSame(moment(game.dateTime), 'D') &&
                        (game.homeTeam.teamId === pick.teamId || game.awayTeam.teamId === pick.teamId)
                    );
                });
                return <GameCard game={game} key={game.gameId} sport={sport} vipPlay={isVIPPlay} setSubpage={setSubpage} />;
            });
        } else {
            return <NoData />;
        }
    };

    if (loaders.find(loader => loader === 'game-data')) {
        return (
            <div className="grid-content-wrapper">
                <LoadingSpinner loading={true} />
            </div>
        );
    }
    return (
        <div>
            <GameSearchFilter
                gameData={gameData}
                startDate={gameData?.length ? moment(gameData.hasMin('dateTime').dateTime) : moment().toISOString()} // This is used to reset the filters
                activeDate={activeDate} // This is the active date
                setActiveDate={setActiveDate}
                value={value}
                setValue={setValue}
                ratingFilter={ratingFilter}
                setRatingFilter={setRatingFilter}
            />
            <div className="grid-content-wrapper">{processGameCards()}</div>
            <BackToTop />
        </div>
    );
};

export default GameCards;
