// Library imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Controller imports
import { searchBuilderQuery } from '../../controllers/GetDataController';

export const queryDatabase = createAsyncThunk('queryBuilder/queryDatabase', async (sport, { getState, rejectWithValue }) => {
    try {
        const { queryBuilder } = getState();
        const data = queryBuilder.data;
        if (!data.awayTeam && !data.homeTeam) {
            return rejectWithValue('Please enter at least one team to query.');
        }
        if (!sport) {
            return rejectWithValue('You must provide a sport.');
        }
        const res = await searchBuilderQuery(data, sport);
        return res;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const calculateRecord = (games, teamId) => {
    const wins = games.filter(game => game.winner === teamId).length;
    const losses = games.filter(game => game.winner !== teamId).length;
    return { wins, losses };
};

const calculateATSRecord = (games, teamId) => {
    const atsWins = games.filter(game => game.spreadWinner === teamId).length;
    const atsLosses = games.filter(game => game.spreadWinner !== teamId).length;
    return { atsWins, atsLosses };
};

const calculateAlgoRecord = games => {
    var algoPowerlineWins = 0;
    var algoPowerlineLosses = 0;
    var algoSpreadWins = 0;
    var algoSpreadLosses = 0;
    games.forEach(game => {
        if (game.winner === game.prediction.powerLineWinner) {
            algoPowerlineWins++;
        } else {
            algoPowerlineLosses++;
        }
        if (game.spreadWinner === game.prediction.spreadWinner) {
            algoSpreadWins++;
        } else {
            algoSpreadLosses++;
        }
    });
    return {
        algoPowerlineWins,
        algoPowerlineLosses,
        algoSpreadWins,
        algoSpreadLosses,
    };
};

const initialState = {
    data: {
        awayTeam: '',
        homeTeam: '',
        awayOdds: '',
        homeOdds: '',
        awayOddsSymbol: '=',
        homeOddsSymbol: '=',
        day: '',
        time: '',
    },
    activeFields: [],
    games: [],
    searchResultsLoading: false,
    searchDrawerLoading: false,
    error: '',
    focused: {},
    comingFromGameCard: false,
};

const queryBuilderSlice = createSlice({
    name: 'queryBuilder',
    initialState: initialState,
    reducers: {
        updateData(state, { payload }) {
            state.data[payload.property] = payload.value;
        },
        setData(state, { payload }) {
            state.data = { ...state.data, ...payload };
            state.comingFromGameCard = true;
        },
        toggleField(state, { payload }) {
            if (state.activeFields.includes(payload)) {
                const { [payload]: _, [`${payload}Symbol`]: __, ...newData } = state.data;
                return {
                    ...state,
                    activeFields: state.activeFields.filter(field => field !== payload),
                    data: newData,
                };
            } else {
                state.activeFields = [...state.activeFields, payload];
                state.data = {
                    ...state.data,
                    [payload]: '',
                    [`${payload}Symbol`]: '=',
                };
            }
        },
        setFocused(state, { payload }) {
            const focusedTeamId = payload;
            state.focused = {
                teamName: focusedTeamId,
                ...calculateRecord(state.games, focusedTeamId),
                ...calculateATSRecord(state.games, focusedTeamId),
                ...calculateAlgoRecord(state.games, focusedTeamId),
            };
        },
        setError(state, { payload }) {
            state.error = payload;
        },
        resetData(state, { payload }) {
            if (payload === 'comingFromGameCard') {
                state.comingFromGameCard = false;
            } else {
                return { ...initialState };
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(queryDatabase.pending, state => {
            state.searchResultsLoading = true;
            state.error = '';
        });
        builder.addCase(queryDatabase.fulfilled, (state, { payload }) => {
            state.games = payload.reverse();
            if (payload.length === 0) {
                state.error = 'This query had 0 results.';
            }
            const focusedTeamId = state.data.awayTeam ? state.data.awayTeam : state.data.homeTeam;
            state.focused = {
                teamName: focusedTeamId,
                ...calculateRecord(payload, focusedTeamId),
                ...calculateATSRecord(payload, focusedTeamId),
                ...calculateAlgoRecord(payload, focusedTeamId),
            };
            state.searchResultsLoading = false;
            state.comingFromGameCard = false;
        });
        builder.addCase(queryDatabase.rejected, (state, { payload }) => {
            state.error = payload;
            state.searchResultsLoading = false;
            state.comingFromGameCard = false;
        });
    },
});

export const { setData, updateData, setError, resetData, setFocused, toggleField } = queryBuilderSlice.actions;

export default queryBuilderSlice.reducer;
