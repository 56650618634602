// Library imports
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Util imports
import { EDITABLE_TRENDS } from '../../../utils/constants';
// Styled components
import { TrendDescription, TrendTitle } from '../styles';
import { TrendListContainer } from './styles';
// Action imports
import { setAmount, setTrend } from '../../../store/slices/trendBuilderSlice';

const TrendList = () => {
    const { trend: activeTrend } = useSelector(state => state.trendBuilder);
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <TrendListContainer style={{ overflow: 'scroll' }} flush>
            {EDITABLE_TRENDS.map((trend, i) => {
                return (
                    <ListGroup.Item
                        key={i}
                        action
                        onClick={() => {
                            history.push({
                                pathname: location.pathname,
                                search: `?subpage=trends&trend=${trend.id}`,
                            });
                            dispatch(setTrend(trend));
                        }}
                        variant={trend.id === activeTrend.id ? 'success' : ''}
                        className="mb-2"
                    >
                        <TrendTitle>{trend.title}</TrendTitle>
                        <TrendDescription>{trend.description}</TrendDescription>
                    </ListGroup.Item>
                );
            })}
        </TrendListContainer>
    );
};

export default TrendList;
