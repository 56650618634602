// Component imports
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars, faChartBar, faChartLine, faCogs, faList, faQuestion, faQuestionCircle, faTools } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import queryString from 'query-string';
import { useHistory, useParams } from 'react-router-dom';
// Component imports
import GameCards from '../GameCards';
import Trends from '../Trends';
import Algorithm from '../Algorithm';
import SearchBuilder from '../SearchBuilder';
import Props from '../Props';
import Header from '../../components/Header';
import Sidebar from '../Sidebar';
import Footer from '../../components/Footer';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import UpgradeTooltip from '../../components/UpgradeTooltip';
// Controllers imports
import { updateReciept } from '../../controllers/PaymentController';
import { updateProfile } from '../../controllers/UserController';
// Action imports
import { fetchUser, setUser } from '../../store/slices/userSlice';
import { fetchTeams, fetchGames, setSport, setFilters } from '../../store/slices/appSlice';
import { resetData } from '../../store/slices/queryBuilderSlice';
// Util imports
import { convertToISOString, scrollToTop } from '../../utils';
import useScreenWidth from '../../utils/hooks/useScreenWidth';
import { ADVANCED_SEARCH_SCREEN_SIZE, TOUR_CONFIGS } from '../../utils/constants';
// CSS imports
import '../../css/Grid.css';
// Styled components
import { AppContent, SportLabel, ToolsNavbar, TourButton } from './styles';

const App = () => {
    // Routing
    const history = useHistory();
    const { sport: urlSport } = useParams();
    let params = queryString.parse(window.location.href.split('?')[1]);
    // Redux
    const dispatch = useDispatch();
    const { sport, openDrawer } = useSelector(state => state.app);
    const { user, userLoading } = useSelector(state => state.user);
    // Local state
    const [subpage, setSubpage] = useState(params.subpage ? params.subpage : 'games');
    const [isTourOpen, setIsTourOpen] = useState(false);
    // Hooks
    const windowWidth = useScreenWidth();

    useEffect(() => {
        if (urlSport !== sport) {
            dispatch(setSport(urlSport));
        } else {
            // Only fetch new data once the url and state sport match
            dispatch(setFilters('Upcoming'));
            dispatch(fetchGames());
            dispatch(fetchTeams());
            // If subpage is not in the URL make sure to add it
            if (!params.subpage) {
                params.subpage = subpage;
                history.replace({ search: queryString.stringify(params) });
            }
        }
    }, [sport, urlSport]);

    useEffect(() => {
        sessionStorage.removeItem('lp_expired');
        dispatch(fetchUser());
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                // Verify we have an active user
                if (user.reciept.endDate && moment(user.reciept.endDate).isBefore(moment.now()) && user.reciept.type === 'account') {
                    // #1 Check if user is a Stripe customer, has an active subscription
                    // #2 Check that the subscription period end date is after today (they have paid for next month)
                    // #3 Make sure the subscription isn't past due (failed payment)
                    if (
                        user.cust?.subscriptions?.data?.length &&
                        moment.unix(user.cust.subscriptions.data[0].current_period_end).isAfter(moment()) &&
                        user.cust?.subscriptions.data[0].status !== 'past_due'
                    ) {
                        await updateReciept(user.reciept._id, 'endDate', convertToISOString(moment.unix(user.cust.subscriptions.data[0].current_period_end)));
                    } else {
                        sessionStorage.setItem('lp_expired', 'true');
                        window.location = '/profile?subpage=settings';
                        return;
                    }
                }
                sessionStorage.setItem('lp_expired', 'false');
                setIsTourOpen(user.tour);
            } catch (error) {
                sessionStorage.setItem('lp_expired', 'false');
                console.log(`Error initializing app.\n${error}`);
            }
        }
        if (user.email) {
            if (!sessionStorage.getItem('lp_expired') || sessionStorage.getItem('lp_expired') === 'true') {
                fetchData();
            }
        }
    }, [user]);

    useEffect(() => {
        scrollToTop();
        if (subpage !== 'search-builder') {
            dispatch(resetData());
        }
    }, [subpage]);

    if (userLoading) {
        return (
            <div className="page-wrapper" style={{ height: '100vh' }}>
                <LoadingSpinner loading={userLoading} />
            </div>
        );
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>{String(sport).toUpperCase()} | Line Prophet</title>
                <meta
                    name="description"
                    content="Get all the head to head matchup data you need to gain an edge over the books.  Go in depth with Line Prophet data and algorithmic predictions."
                />
            </Helmet>
            <Header currentPage={sport} lightMode={true} showBanner={true} />
            <AppContent>
                <ToolsNavbar
                    collapseOnSelect
                    expand="md"
                    bg="light"
                    variant="light"
                    drawerOpen={user.reciept.type === 'free-account' || openDrawer || isTourOpen}
                >
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faTools} style={{ height: '1.5em' }} />
                            </div>
                        </Navbar.Toggle>
                        <SportLabel>{String(sport).toUpperCase()}</SportLabel>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav
                                className="justify-content-center w-100"
                                activeKey={subpage}
                                onSelect={selectedKey => {
                                    setSubpage(selectedKey);
                                    history.push(`?subpage=${selectedKey}`);
                                }}
                            >
                                <Nav.Link eventKey="games">
                                    <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faList} /> Games
                                </Nav.Link>
                                {user.reciept.type === 'free-account' ? (
                                    <UpgradeTooltip
                                        children={
                                            <Nav.Link>
                                                <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faBinoculars} /> Props
                                            </Nav.Link>
                                        }
                                    />
                                ) : (
                                    <Nav.Link eventKey="props">
                                        <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faBinoculars} /> Props
                                    </Nav.Link>
                                )}
                                {user.reciept.type === 'free-account' ? (
                                    <UpgradeTooltip
                                        children={
                                            <Nav.Link>
                                                <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faBinoculars} /> Search Builder
                                            </Nav.Link>
                                        }
                                    />
                                ) : (
                                    <Nav.Link disabled={windowWidth < ADVANCED_SEARCH_SCREEN_SIZE} eventKey="search-builder">
                                        <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faCogs} /> Search Builder
                                    </Nav.Link>
                                )}
                                {user.reciept.type === 'free-account' ? (
                                    <UpgradeTooltip
                                        children={
                                            <Nav.Link>
                                                <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faChartBar} /> Trends
                                            </Nav.Link>
                                        }
                                    />
                                ) : (
                                    <Nav.Link disabled={windowWidth < ADVANCED_SEARCH_SCREEN_SIZE} eventKey="trends">
                                        <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faChartBar} /> Trends
                                    </Nav.Link>
                                )}
                                {user.reciept.type === 'free-account' ? (
                                    <UpgradeTooltip
                                        children={
                                            <Nav.Link>
                                                <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faChartLine} /> Algorithm
                                            </Nav.Link>
                                        }
                                    />
                                ) : (
                                    <Nav.Link eventKey="algorithm">
                                        <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faChartLine} /> Algorithm
                                    </Nav.Link>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                    {TOUR_CONFIGS[subpage] ? (
                        <TourButton onClick={() => setIsTourOpen(true)} variant="link" id="tour-button">
                            <FontAwesomeIcon style={{ fontSize: '20px' }} icon={faQuestionCircle} />
                        </TourButton>
                    ) : (
                        <></>
                    )}
                </ToolsNavbar>
                {subpage === 'games' ? (
                    <GameCards setSubpage={setSubpage} />
                ) : subpage === 'props' ? (
                    <Props />
                ) : subpage === 'trends' ? (
                    <Trends setSubpage={setSubpage} />
                ) : subpage === 'algorithm' ? (
                    <Algorithm />
                ) : subpage === 'search-builder' ? (
                    <SearchBuilder />
                ) : (
                    <></>
                )}
            </AppContent>
            <Sidebar tabs={['vip', 'standings']} />
            <Footer />
            <Tour
                onRequestClose={async () => {
                    setIsTourOpen(false);
                    const user = await updateProfile('tour', false);
                    dispatch(setUser(user));
                }}
                steps={TOUR_CONFIGS[subpage]}
                isOpen={isTourOpen}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={'#5cb7b7'}
                startAt={0}
            />
        </div>
    );
};

export default App;
