import queryBuilderSlice from './slices/queryBuilderSlice';
import appSlice from './slices/appSlice';
import userSlice from './slices/userSlice';
import registerSlice from './slices/registerSlice';
import trendBuilderSlice from './slices/trendBuilderSlice';

const rootReducer = {
    queryBuilder: queryBuilderSlice,
    app: appSlice,
    user: userSlice,
    register: registerSlice,
    trendBuilder: trendBuilderSlice,
};

export default rootReducer;
