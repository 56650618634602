// Library imports
import React from 'react';
import { Row } from 'react-bootstrap';
import { array, string } from 'prop-types';
// Styled Components
import { CardTitle, HighLightedField } from '../../app_pages/GameCards/game_card_windows/styles';
import { CompareListCol } from './styles';

const CompareList = ({ labels, dataSetOne, dataSetOneTitle, dataSetOneTitleColor, dataSetTwo, dataSetTwoTitle, dataSetTwoTitleColor }) => {
    return (
        <Row>
            <CompareListCol xs={4} style={{ paddingRight: 0 }}>
                <CardTitle color={dataSetOneTitleColor} style={{ paddingRight: 0, paddingLeft: 0 }}>
                    {dataSetOneTitle}
                </CardTitle>
                {dataSetOne.map((value, i) => {
                    const fieldClass = value.class !== undefined ? value.class : 'light';
                    const fieldValue = value.value !== undefined ? value.value : value;
                    return (
                        <HighLightedField key={i} className="data-value" variant={fieldClass} style={{ marginBottom: '3px' }}>
                            {fieldValue}
                        </HighLightedField>
                    );
                })}
            </CompareListCol>
            <CompareListCol xs={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <CardTitle color={dataSetOneTitleColor} style={{ visibility: 'hidden' }}>
                    filter
                </CardTitle>
                {labels.map((value, i) => {
                    return (
                        <HighLightedField key={i} variant="light" style={{ marginBottom: '3px', padding: '5px 0px', backgroundColor: 'transparent' }}>
                            {value}
                        </HighLightedField>
                    );
                })}
            </CompareListCol>
            <CompareListCol xs={4} style={{ paddingLeft: 0 }}>
                <CardTitle color={dataSetTwoTitleColor} style={{ alignSelf: 'flex-end', paddingRight: 0, paddingLeft: 0 }}>
                    {dataSetTwoTitle}
                </CardTitle>
                {dataSetTwo.map((value, i) => {
                    const fieldClass = value.class !== undefined ? value.class : 'light';
                    const fieldValue = value.value !== undefined ? value.value : value;
                    return (
                        <HighLightedField key={i} variant={fieldClass} style={{ marginBottom: '3px' }}>
                            {fieldValue}
                        </HighLightedField>
                    );
                })}
            </CompareListCol>
        </Row>
    );
};

CompareList.defaultProps = {
    labels: [],
    dataSetOne: [],
    dataSetOneTitle: '',
    dataSetOneTitleColor: '#000',
    dataSetTwo: [],
    dataSetTwoTitle: '',
    dataSetTwoTitleColor: '#000',
};

CompareList.propTypes = {
    labels: array,
    dataSetOne: array,
    dataSetOneTitle: string,
    dataSetOneTitleColor: string,
    dataSetTwo: array,
    dataSetTwoTitle: string,
    dataSetTwoTitleColor: string,
};

export default CompareList;
