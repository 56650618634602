// Library imports
import React, { useState } from 'react';
import { Row, Col, Card, Nav, ListGroup } from 'react-bootstrap';
// Util imports
import { shortName, addPlusSign, formatOddButtonJuice } from '../../../../utils';
// Styled components
import { CardTitle } from '../styles';
import { OddButton, OddLabel } from './styles';

const OddsBoard = ({ game, setView, pickData, setPickData }) => {
    const [oddFilter, setOddFilter] = useState('fullGame');

    const renderOdds = () => {
        if (oddFilter === 'fullGame' || oddFilter === 'half' || oddFilter === 'quarter') {
            return [game.awayTeam, game.homeTeam].map((team, i) => {
                const oddTypes = oddFilter === 'fullGame' ? ['moneyline', 'spread'] : ['moneyline', 'spread', 'total'];
                return (
                    <Col xs={6} sm={6} className={`p-0 ${i === 0 ? 'pr-1' : ''}`} key={i}>
                        <Card style={{ height: '100%' }}>
                            <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                            <ListGroup variant="flush">
                                {oddTypes.map((type, j) => {
                                    const oddDesc = oddFilter !== 'fullGame' ? ` (${oddFilter})` : '';
                                    const odds = team.odds?.[oddFilter]?.[type] ? team.odds[oddFilter][type] : 0;
                                    return (
                                        <ListGroup.Item key={j} className="odds-list-item">
                                            <div className="odds-desc-wrapper">
                                                <OddLabel>{type === 'total' ? 'Total' : shortName(team)}</OddLabel>
                                            </div>
                                            <OddButton
                                                onClick={() => {
                                                    setPickData({
                                                        ...pickData,
                                                        // Fullgame totals are not done here - they are done in the totals section
                                                        teamName:
                                                            type === 'total'
                                                                ? `${game.awayTeam.abbr}-${game.homeTeam.abbr}${oddDesc}`
                                                                : `${team.teamName}${oddDesc}`,
                                                        pickTeamId: team.teamId,
                                                        pickValue: odds,
                                                        // Fullgame totals are not done here - they are done in the totals section
                                                        pickType: type === 'total' ? (i === 0 ? `${oddFilter}-under` : `${oddFilter}-over`) : type,
                                                    });
                                                    setView('confirm');
                                                }}
                                            >
                                                <span className="value">{type === 'total' ? (i === 0 ? `u${odds}` : `o${odds}`) : addPlusSign(odds)}</span>
                                                <span className="juice">{formatOddButtonJuice(-110, type)}</span>
                                            </OddButton>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </Card>
                    </Col>
                );
            });
        }
        if (oddFilter === 'total') {
            return (
                <Col className={`p-0 pr-1`}>
                    <Card style={{ width: '50%' }}>
                        <CardTitle>Totals</CardTitle>
                        <ListGroup>
                            <ListGroup.Item className="odds-list-item">
                                <div className="odds-desc-wrapper">
                                    <OddLabel>Total</OddLabel>
                                </div>
                                <OddButton
                                    id="under"
                                    onClick={e => {
                                        setPickData({
                                            ...pickData,
                                            teamName: `${game.awayTeam.abbr}-${game.homeTeam.abbr}`,
                                            pickTeamId: `${game.awayTeam.teamId}-${game.homeTeam.teamId}`,
                                            pickValue: game.total,
                                            pickType: e.currentTarget.id,
                                        });
                                        setView('confirm');
                                    }}
                                >
                                    <span className="value">u{game.total}</span>
                                    <span className="juice">{formatOddButtonJuice(-110, 'total')}</span>
                                </OddButton>
                            </ListGroup.Item>
                            <ListGroup.Item className="odds-list-item">
                                <div className="odds-desc-wrapper">
                                    <OddLabel>Total</OddLabel>
                                </div>
                                <OddButton
                                    id="over"
                                    onClick={e => {
                                        setPickData({
                                            ...pickData,
                                            teamName: `${game.awayTeam.abbr}-${game.homeTeam.abbr}`,
                                            pickTeamId: `${game.awayTeam.teamId}-${game.homeTeam.teamId}`,
                                            pickValue: `${game.total}`,
                                            pickType: e.currentTarget.id,
                                        });
                                        setView('confirm');
                                    }}
                                >
                                    <span className="value">o{game.total}</span>
                                    <span className="juice">{formatOddButtonJuice(-110, 'total')}</span>
                                </OddButton>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            );
        }
        return <></>;
    };

    return (
        <div>
            <Nav variant="tabs" className="mb-2" activeKey={oddFilter}>
                <Nav.Item>
                    <Nav.Link eventKey={'fullGame'} onClick={() => setOddFilter('fullGame')}>
                        Game
                    </Nav.Link>
                </Nav.Item>
                {game.awayTeam.odds?.quarter && game.homeTeam.odds?.quarter ? (
                    <Nav.Item>
                        <Nav.Link eventKey={'quarter'} onClick={() => setOddFilter('quarter')}>
                            1Q
                        </Nav.Link>
                    </Nav.Item>
                ) : (
                    <></>
                )}
                {game.awayTeam.odds?.half && game.homeTeam.odds?.half ? (
                    <Nav.Item>
                        <Nav.Link eventKey={'half'} onClick={() => setOddFilter('half')}>
                            Half
                        </Nav.Link>
                    </Nav.Item>
                ) : (
                    <></>
                )}
                <Nav.Item>
                    <Nav.Link eventKey={'total'} onClick={() => setOddFilter('total')}>
                        Totals
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <Row className="m-0" style={{ alignItems: 'flex-start' }}>
                {renderOdds()}
            </Row>
        </div>
    );
};

export default OddsBoard;
